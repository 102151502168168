@import "../variables";

.iconWrapper {
  display: flex;
  width: 22px;
  height: auto;
  align-items: center;
  justify-content: center;
}

.dashboard {
  display: flex;
  position: fixed;
  right: 21px;
  left: 21px;
  z-index: 1101;
  height: calc(100vh - 119px);
}

.dashboardMenu {
  width: 240px;
  background: $dashboard-bg;
  border-right: 1px solid $light-blue;
  padding: 25px 15px;
  overflow: scroll;
  height: 100%;
}

.dashboardBody {
  width: calc(100% - 240px);
  height: 100%;
  overflow: scroll;

  &_withoutScroll {
    overflow: hidden;
  }
}

.menuText {
  display: inline-block;
  padding-left: 10px;
}

.dashboardLinkBlock {
  margin: 0;
  list-style-type: none;
  padding-left: 32px;
  width: 100%;

  li {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    padding-left: 5px;

    &:first-child {
      margin-top: unset;
    }

    &:last-child {
      margin-bottom: unset;
    }
  }

  .link,
  %link {
    display: inline-block;
    width: calc(100% - 37px);
    padding: 7px 11px 7px 14px;
    border-radius: 7px;
    color: #69767a;
    font-size: 15px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
      background: #e7f5ffe5;
      color: $blue-2;
    }
  }

  .activeLink {
    @extend %link;

    background: #e7f5ffe5;
    color: $blue-2;
    transition: all 0.2s ease-in-out;
  }
}

.Onelink:hover svg {
  g[data-name="schedule"] {
    path[data-name="Контур 13723"],
    g[data-name="Эллипс 7"] {
      stroke: $blue-2 !important;
    }
  }
}

.Onelink,
%Onelink {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #69767a;
  text-decoration: none;
  margin-bottom: 10px;
  height: 30px;
  padding-left: 5px;

  .textLink {
    display: inline-block;
    padding-left: 10px;
  }

  &:hover {
    color: $blue-2;

    path {
      fill: $blue-2;
    }
  }
}

.activeOnelink {
  @extend %Onelink;

  color: $blue-2;

  /* stylelint-disable no-descending-specificity */
  path {
    fill: $blue-2;
  }
}
