.eventContent {
  align-items: center;
  flex: 1;
  padding: 6px;
  display: flex;
  visibility: hidden;
  width: 100%;
  $eventContent: &;

  &__menu {
    max-width: 260px;
  }

  &__invisible,
  &__invisible:hover {
    position: absolute;
    left: 50%;
    background: transparent;
    visibility: hidden;

    svg {
      display: none;
    }
  }

  &__night_end {
    position: absolute;
    visibility: visible;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(135, 135, 135, 0.7);
    width: 0;
    z-index: -1;
    border-radius: 0 4px 4px 0;
  }

  &__night_start {
    position: absolute;
    visibility: visible;
    left: 0;
    top: 0;
    bottom: 0;
    background: rgba(135, 135, 135, 0.7);
    width: 0;
    z-index: -1;
    border-radius: 4px 0 0 4px;
  }

  &__removeTimelines {
    .eventContent__night_end,
    .eventContent__night_start {
      display: none;
    }
  }

  &__light {
    color: #808f94;
  }

  &__line {
    width: 3px;
    height: 35px;
    margin-right: 5px;
    visibility: visible;
    border-radius: 3px;
  }

  &__time {
    visibility: visible;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 5px;
  }

  &__title {
    font-size: 12px;
    line-height: 15px;
    font-family: Helvetica Neue, sans-serif;
    color: #333945;
    width: 100%;
    visibility: visible;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    flex-wrap: nowrap;
    white-space: nowrap;
  }

  &__place {
    font-size: 12px;
    line-height: 14px;
    font-family: Helvetica Neue, sans-serif;
    color: #fff;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__job_type {
    font-size: 11px;
    line-height: 14px;
    font-family: Helvetica Neue, sans-serif;
    color: #fff;
    opacity: 0.8;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__leftSpace {
    margin-left: auto;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    width: 168px;

    &__avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 10px;
    }

    &__right {
      display: flex;
      flex-direction: column;

      &__fullName {
        font-size: 14px;
        font-family: Helvetica Neue, sans-serif;
        color: black;
        line-height: 17px;
      }

      &__jobType {
        font-size: 11px;
        font-family: "Helvetica Neue Medium", sans-serif;
        color: #808f94;
        line-height: 16px;
      }
    }
  }

  &__userReccuring {
    border: 1px solid #D8E3ED;
    border-radius: 4px;
    padding: 8px;
    margin-top: 10px;

    &_head {
      color: #FD9D27;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: Helvetica Neue, sans-serif;
      line-height: 16px;
      gap: 4px;
    }

    &_body {
      &_section {
        margin-top: 7px;
        font-size: 10px;
        line-height: 12px;
        color: #808F94;

        b {
          color: black;
        }
      }
  
      &_buttons {
        display: flex;
        align-items: center;
        margin-top: 5px;
        gap: 3px;
        flex-wrap: wrap;
      }
    }

    &_icon {
      width: 12px;
      height: 12px;
      flex: 0 0 12px;
      display: block;
      opacity: 1;
      visibility: visible;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }

    &__action {
      display: flex;
      align-items: center;
      color: #333945;
      font-family: Helvetica Neue, sans-serif;
      font-size: 12px;
      line-height: 14px;
      background: transparent;
      border: none;
      cursor: pointer;
      padding: 9px 0;
      width: 100%;
      border-top: 1px solid rgba(112, 112, 112, 0.24);
      margin-top: 10px;
  
      &_delete {
        color: #818f94;
        margin-top: 0px;
        margin-bottom: -10px;
      }
    }
  }

  &__group {
    opacity: 1;
    width: 100%;
    visibility: visible;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif;
    cursor: pointer;

    &_icon {
      width: 24px;
      height: 24px;
      display: flex;
    }
  }

  &__groupModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
    }

    &__add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      font-size: 12px;
      color: #333945;
      font-weight: 600;
    }

    &__count {
      font-size: 12px;
      color: #818F94;
    }

    &__list {
      width: 100%;
    }

    &__item {
      margin: 0 -15px;
      padding: 9px 15px;
      cursor: pointer;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        border: 0.5px solid #707070;
        height: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        opacity: 0.24;
      }

      &:first-child::before {
        content: ' ';
        position: absolute;
        border: 0.5px solid #707070;
        height: 0;
        left: 15px;
        right: 15px;
        top: 0;
        opacity: 0.24;
      }

      &_completed {
        background: #F0F7F1;
        cursor: default;
      }

      &:hover {
        background: #F7F7F7;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        color: #333945;
        display: flex;
        align-items: center;
        padding-right: 16px;
      }

      &__time {
        font-size: 12px;
        color: #818F94;
      }

      &__place {
        font-size: 12px;
        color: #818F94;
      }
    }
  }

  &__comment_icon {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 10px;
    height: 10px;
    display: flex;
  }

  &__description {
    color: #818F94;
    font-size: 12px;
    margin-top: 10px;
  }

  &__photo {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
    visibility: visible;
    margin-right: 4px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .eventContent__reccuring {
      position: absolute;
      top: -2px;
      right: -6px;
    }
  }

  &__reccuring {
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    display: block;
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  &__label {
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif;
    color: #808f94;
    line-height: 16px;
    margin-top: 11px;

    &__reccuring {
      font-size: 11px;
      margin-top: 2px;
    }
  }

  &__value {
    font-size: 12px;
    font-family: "Helvetica Neue Medium", sans-serif;
    color: #333945;
    line-height: 16px;
    margin-top: 3px;
    margin-bottom: 12px;

    &__reccuring {
      font-size: 11px;
      margin-bottom: 2px;
      margin-top: 0;
    }
  }

  &__space {
    margin-top: -7px;
  }

  &__rightSpace {
    width: 0;
  }
}
.dayEnd{
  background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #e5e5ed 5px, #e5e5ed 10px);
  opacity: 0.2;
  visibility: hidden;
  overflow: hidden;
}

.dayGoneBe{
  background: transparent;
  opacity: 1;
}