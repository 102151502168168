.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  flex: 1 0 50px;
  color: #333945;
  font-size: 12px;
  font-family: 'Helvetica Neue Medium', sans-serif;
  line-height: 16px;
  text-align: center;
  min-width: 0;
  position: relative;
  $cell: &;
  background: #fff;

  &_past {
    // passing day
    // past
    color: #818f94;
  }

  &_today {
    color: #1d88fc;
  }

  &_night {
    color: #fd9d27;
    display: block;
    border-top: 1px solid #fd9d27;
  }

  &_holiday {

    &_company:after {
      content: '';
      display: block;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #FD9D27 5px, #FD9D27 10px);
      z-index: 2;
      opacity: 0.1;
    }

    &_government:after {
      content: '';
      display: block;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #40c3e2 5px, #40c3e2 10px);
      z-index: 2;
      opacity: 0.1;
      pointer-events: none;
    }
  }

  &_article {
    flex: 1 0 120px;
    justify-content: flex-start;
    padding: 0 15px;
    position: absolute;
    left: 0;
    z-index: 4;
    background: white;
    height: 39px;
    width: 122px;
    border-right: 1px solid #d9dfe3;
  }

  &_statistic {
    position: absolute;
    height: 39px;
    width: 50px;
    right: 0;
    z-index: 3;

    &:nth-last-child(2) {
      right: 51px;
    }

    &:nth-last-child(3) {
      right: 102px;
    }

    &:nth-last-child(4) {
      right: 153px;
    }

    &:nth-last-child(5) {
      right: 204px;
    }

    &:nth-last-child(6) {
      right: 255px;
    }

    // &_time:nth-last-child(2) {
    //   right: 51px;
    // }

    // &_time:nth-last-child(1) {
    //   right: 51px;
    // }

    // &_time:nth-last-child(4) {
    //   right: 153px;
    // }

    // &_planned:nth-last-child(3) {
    //   right: 102px;
    // }

    // &_target:nth-last-child(2) {
    //   right: 51px;
    // }
    
    &_cost {
      z-index: 3;
    }
  }

  &_header {
    justify-content: center;
    border-bottom: 1px solid #d9dfe3;
    background: #fafbfc;
    height: 54px;
  }

  &_weekend {
    background-color: #f9fbfc;
  }

  &__content {
    position: relative;
    pointer-events: none;
    $content: &;

    &__placeholder {
      visibility: hidden;
      position: absolute;
    }
  }

  &_doesNotFit {
    color: #1d88fc;

    #{$cell} {
      &__content {
        z-index: 2;
        pointer-events: auto;

        &__tooltip {
          background: #fff;
          border-radius: 6px;
        }

        &__text {
          overflow: hidden;
          border-radius: 2px;
          background-color: #edf7fa;
          padding: 1px;
          box-sizing: border-box;
        }
      }
    }
  }
}
