.cellOptions {
  align-items: center;
  flex: 1;
  padding: 6px;
  display: flex;
  visibility: hidden;
  position: absolute;
  right: -20px;
  top: 0;
  z-index: 100;
  
  $cellOptions: &;

  &__withoutMenu {
    right: 2px;
    top: 2px;
    bottom: 2px;
    left: 2px;
    visibility: visible;
  }

  &__invisible,
  &__invisible:hover {
    position: absolute;
    left: 50%;
    background: transparent;
    visibility: hidden;

    svg {
      display: none;
    }
  }

  &__time {
    visibility: visible;
  }

  &__avatar {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 5px;
  }

  &__title {
    font-size: 12px;
    line-height: 15px;
    font-family: Helvetica Neue, sans-serif;
    color: #333945;
    width: 100%;
    visibility: visible;
  }

  &__userInfo {
    display: flex;
    align-items: center;
    width: 168px;

    &__avatar {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      margin-right: 10px;
    }

    &__right {
      display: flex;
      flex-direction: column;

      &__fullName {
        font-size: 14px;
        font-family: Helvetica Neue, sans-serif;
        color: black;
        line-height: 17px;
      }

      &__jobType {
        font-size: 11px;
        font-family: "Helvetica Neue Medium", sans-serif;
        color: #808f94;
        line-height: 16px;
        margin-top: 2px;
      }
    }
  }

  &__label {
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif;
    color: #808f94;
    line-height: 16px;
    margin-top: 11px;
  }

  &__value {
    font-size: 12px;
    font-family: "Helvetica Neue Medium", sans-serif;
    color: #333945;
    line-height: 16px;
    margin-top: 3px;
    margin-bottom: 12px;
  }

  &__space {
    margin-top: -7px;
  }

  &:hover .copyAdd {
    visibility: visible;
  }

  &__group {
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 12px;
    font-family: Helvetica Neue, sans-serif;
    cursor: pointer;

    &_icon {
      width: 24px;
      height: 24px;
      display: flex;
    }

    &_button {
      padding: 6px;
      position: absolute;
      right: -20px;
      top: 0;
      z-index: 100;
      visibility: hidden;

      &_dots {
        background-color: rgba(34, 36, 49, 0.5);
        display: flex;
        align-self: center;
        align-items: center;
        justify-content: center;
        width: 12px;
        min-width: 12px;
        height: 24px;
        border-radius: 12px;
        padding: 0;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #222431;
        }
      }
    }
  }

  &__groupModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    
    &::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #fff;
    }

    &__count {
      font-size: 12px;
      color: #818F94;
    }

    &__list {
      width: 100%;
    }

    &__add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      cursor: pointer;
      font-size: 12px;
      color: #333945;
      font-weight: 600;
    }

    &__item {
      margin: 0 -15px;
      padding: 9px 15px;
      cursor: pointer;
      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        border: 0.5px solid #707070;
        height: 0;
        left: 15px;
        right: 15px;
        bottom: 0;
        opacity: 0.24;
      }

      &:first-child::before {
        content: ' ';
        position: absolute;
        border: 0.5px solid #707070;
        height: 0;
        left: 15px;
        right: 15px;
        top: 0;
        opacity: 0.24;
      }

      &_completed {
        background: #F0F7F1;
      }

      &:hover {
        background: #F7F7F7;
      }

      &__title {
        font-weight: 600;
        font-size: 12px;
        color: #333945;
        display: flex;
        align-items: center;
        padding-right: 16px;
      }

      &__time {
        font-size: 12px;
        color: #818F94;
      }

      &__place {
        font-size: 12px;
        color: #818F94;
      }
    }
  }

  &__reccuring {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    flex: 0 0 12px;
    display: block;
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;

    svg {
      width: 12px;
      height: 12px;
    }
  }


  &__comment_icon {
    position: absolute;
    top: 10px;
    right: 15px;
    width: 10px;
    height: 10px;
    display: flex;
  }

  &__description {
    color: #818F94;
    font-size: 12px;
    margin-top: 10px;
  }
}
.dayEnd{
  background: repeating-linear-gradient(-45deg, #fff, #fff 5px, #e5e5ed 5px, #e5e5ed 10px);
  opacity: 0.2;
  visibility: hidden;
  overflow: hidden;
}

.emptyAdd {
  pointer-events: all;
  
  &:hover {
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    right: 0px;
    width: auto;
    bottom: 0px;
    height: auto;

    &::before {
      justify-content: center;
      margin: -4px 0 0 -1px !important;
      left: 0 !important;
      right: 0 !important;
      width: auto !important;
    }
  }
}

.copyAdd {
  pointer-events: all;
  line-height: 12px !important;

  &.event {
    right: -3px !important;
    left: -1px !important;

  }
  
  &:hover {
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    visibility: visible;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;

    &::before {
      justify-content: center;
      margin: -4px 0 0 -1px !important;
      left: 0 !important;
      right: 0 !important;
      width: auto !important;
    }
  }
}