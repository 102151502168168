@import "../variables";

$table-header: #1976d2;
$table-header-border: #1565c0;
$table-border: #d9d9d9;
$row-bg: #f4f2f1;
$row-warn-bg: #fffbea;
$row-error-bg: #ffebec;
$icon-transform-default: translateY(0);

header {
  box-sizing: border-box;
}

.headerWrapper {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0 5px 20px rgba(62, 69, 82, 0.06);
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 5px;
  overflow: hidden;
  position: sticky;
  top: 0;
  background: #eaf5ff;
  z-index: 1;
}

.flexRowGlobal {
  display: flex;
  min-width: 120px;
  justify-content: flex-start;
  padding: 0 1px;

  &:last-of-type {
    //justify-content: flex-end;
    padding-right: 10px;
  }
}

// search-table
.headerWrapperSearch {
  @extend .headerWrapper;

  padding-bottom: 50px;
  box-shadow: none;
  background: linear-gradient(to bottom, #eaf5ff calc(100% - 40px), #fff calc(100% - 40px));

  .flexRowGlobal {
    position: relative;
  }

  .headerSearch {
    position: absolute;
    bottom: -40px;
    left: 2px;
    right: 10px;
    width: 100%;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 1px;
    left: 17px;
    right: 0;
    height: 1px;
    width: auto;
    background: $table-border;
  }
}

.simpleTable {
  @extend .headerWrapper;

  background: #fff;
  box-shadow: none;
  border-bottom: 1px solid $row-bg;
  z-index: 2;
}

// search-table END
.cell {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  color: #333945;
  display: flex;
  align-items: center;

  svg {
    padding-right: 5px;
    width: 18px;
    height: 18px;
  }
}

.columnName {
  font-family: "Helvetica Neue Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #4d7499;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;

  .flexRow {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    //border-bottom: solid 1px $table-border;

    //&:hover {
    //  background: #f5f5f5;
    //  transition: 500ms;
    //}
  }
}

.flexTable {
  display: flex;
  flex-flow: wrap;
  //border-left: solid 1px $table-border;
  transition: 0.5s;
  padding: 0 10px;
  width: fit-content;
  min-width: 100%;
  position: relative;

  &.header {
    padding-left: 15px;
    padding-right: 25px;
    flex-flow: nowrap;
  }

  &.flexTableHeaderReports {
    padding: 3px 18px 3px 0;
    margin-left: 15px;
  }

  &.flexTableHeaderWithSelectable {
    padding-left: 45px;
  }

  &.flexTableHeaderNotSelectable {
    padding-left: 45px;
  }

  &:first-of-type {
    //border-top: solid 1px $table-header-border;
    //border-left: solid 1px $table-header-border;
  }

  &:first-of-type .flexRow {
    background: $table-header;
    color: white;
    //border-color: $table-header-border;
  }

  &.row:nth-child(odd) .flexRow {
    background: $row-bg;
  }

  //&:hover {
  //  background: #f5f5f5;
  //  transition: 500ms;
  //}
}

.details {
  min-height: 0;
  overflow: hidden;
  padding-top: 5px;

  .flexTable {
    padding: 0;
  }
}

.detailsNotSelectable {
  padding-left: 25px;
}

.detailsReports {
  .flexTable {
    padding: 0 0 0 10px;
  }
}

.datailsHidden {
  height: 0;
  //transform: scaleY(0);
}

.detailsShown {
  height: auto;
  overflow: visible;
  //transform: scaleY(1);
}

.checkboxCell {
  width: 35px !important;
  min-width: 35px !important;
  display: inline-flex;
  padding: 0 !important;
}

.settingsCell {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 20px !important;
  min-width: 20px !important;
  padding: 0 !important;
  cursor: pointer;

  svg {
    width: 16.63px !important;
    height: 15.54px !important;
  }
}

.settingsPopup {
  background-color: #fff;
  border: 1px solid #0085ff;
  border-radius: 6px;
  position: absolute;
  top: 30px;
  right: 20px;
  width: 200px;
  max-height: 250px;
  overflow: auto;
  padding: 5px 10px;
  z-index: 3;
}

.groupLabel {
  align-items: center;
  height: 34px;
  color: #4d7499;
  display: flex;
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;
  background-color: rgba(49, 156, 255, 0.1);
  border-radius: 6px;
  border: none;
  margin-top: 5px;
  padding: 5px 10px;

  .checkboxCell {
    margin-left: -10px;
  }

  .groupLabelText {
    display: flex;
    user-select: none;
    //color: #4d7499;
    text-transform: uppercase;
    font-family: "Helvetica Neue Medium", sans-serif;
    font-weight: 500;
  }
}

.groupContainer {
  padding: 0 30px 0 15px;
  min-width: 100%;

  &:first-of-type {
    .groupLabel {
      margin-top: 0;
    }
  }

  &.groupContainerReports {
    padding-right: 15px;
  }
}

.groupClickableContainer {
  display: flex;
  width: fit-content;
  cursor: pointer;

  > span {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.groupCell {
  padding: 0;
}

.sortBlock {
  //cursor: pointer;
  display: flex;
  user-select: none;

  svg {
    width: 18px !important;
  }
}

.flexCenter {
  display: flex;
  align-items: center;
  padding: 0 3px 0 2px;
}

.checkbox {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: none !important;

  &:hover {
    background-color: transparent !important;
  }
}

.pointer {
  cursor: pointer;
}

.mr10 {
  margin-right: 10px;
}

.exportLink {
  &:hover {
    opacity: 0.4;
  }
}

.nowrap {
  white-space: nowrap;
}

.collapsIcon {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transform: $icon-transform-default rotate(-90deg);
  width: 8.315px !important;
  height: 100% !important;
  margin-right: 5px;
  padding-right: 0 !important;

  path {
    fill: #0087ff;
  }
}

.collapsIconSelected {
  path {
    fill: #fff;
  }
}

.collapsIconRotated {
  transform: $icon-transform-default rotate(0deg);
}

.rowWrapper {
  min-width: 100%;
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 6px;
  cursor: pointer;
}

.contentVisibility {
  content-visibility: auto;
  contain-intrinsic-size: 32px 1000px;
}

.rowWarning {
  background-color: $row-warn-bg;
}

.rowError {
  background-color: $row-error-bg;
}

.rowSuccess {
  background-color: $light-green-row-background;
}

// .reportsRowSelected {
//   background-color: #fff;
// }

.flexRowGlobalReports {
  padding: 0 8px;

  &:first-of-type {
    padding-left: 30px;
  }

  &:last-of-type {
    //justify-content: flex-end;
    padding-right: 17px;
  }
}

.flexRowGroup {
  position: relative;
  //color: #333945;
  min-width: 120px;
  display: flex;
  justify-content: flex-start;
  padding: 8px;

  &:last-of-type {
    //justify-content: flex-end;
  }
}

.IconStatus {
  display: block;
  position: absolute;
  top: 6px;
  left: 3px;
}

.ActionsTable {
  display: flex;
  align-items: center;
  height: 100%;

  button {
    border: unset;
    background: unset;
    padding: unset;
    margin: unset;
    display: inline-block;
    cursor: pointer;

    &:focus:not(:focus-visible) {
      outline: none;
    }
  }
}

.actions {
  &Visible {
    display: initial;
  }

  &Hidden {
    display: none !important;
  }
}

.absoluteActions {
  display: flex;
  position: absolute;
  padding: 4px 10px 2px 20px;
  //justify-content: flex-end;
  align-items: center;
  width: 120px;
  text-align: right;
  z-index: 1;
  left: calc(var(--hover-actions-left) + var(--scroll-left));
  background: linear-gradient(to right, #d0dfef04 0%, #d0dfef 50%, #d0dfef 100%);
}

.duplicateActions {
  width: auto !important;
  padding: 0 !important;
}

.opacityText {
  opacity: 0;
}

.flexRowGroupReports {
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;

  &:last-of-type {
    display: flex;
    //justify-content: flex-end;
  }
}

.groupLabelReports {
  color: #4d7499;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

.flexRowSimpleTable {
  display: flex;
  text-align: left;
  padding: 0.5em 0 !important;
}

// .flexRowSelected {
//   color: #fff !important;
// }

.reportsFlexRowSelected {
  color: #0085ff;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.approvedIconSelected {
  g {
    fill: #fff !important;
    stroke: #319cff !important;
  }

  path {
    stroke: #319cff !important;
  }
}

.suspendedIconSelected {
  g {
    fill: #fff !important;
    stroke: #319cff;
  }

  path {
    stroke: #319cff !important;
  }
}

.pendingIconSelected {
  g {
    fill: #319cff !important;
    stroke-width: 0;
  }

  path {
    fill: #fff !important;
  }
}

.tableFooter {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  height: 47px;
  padding: 0 17px;
  background-color: #fff;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  //margin-top: 40px;
  box-shadow: 0 -4px 20px rgba(2, 16, 29, 0.11);
  z-index: 2;

  > * {
    padding: 0 5px;
  }

  &:empty {
    display: none;
  }
}

.footerTitleReports {
  margin-left: 390px;
  margin-right: 0px;
  padding-right: 35px;
}

.footerTitle {
  color: #3e4552;
  font-family: "Helvetica Neue Bold", sans-serif;
  font-size: 14px;
  //font-weight: 600;
}

.footerCost {
  min-width: 6ex;
}

.totals {
  font-family: Helvetica Neue, sans-serif;
  font-size: 14px;

  &.blue {
    color: #0085ff;
  }

  &.red {
    color: $red;
  }

  &.green {
    color: $green;
  }
}

.bold {
  font-family: "Helvetica Neue Bold", sans-serif;
  //font-weight: 600;
}

.medium {
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;
}

.total {
  color: #3e4552;
  font-family: "Helvetica Neue Medium", sans-serif;
  font-weight: 500;
}

div {
  box-sizing: border-box;
}

.tableContent {
  min-height: 250px;
  //height: calc(100vh - 200px);
  min-width: 100%;
  padding-bottom: 10px;
  //padding-right: 15px;
  width: fit-content;

  &.tableContentNotSortable {
    //padding-right: 15px;
  }

  //.groupContainer:first-of-type {
  //  .groupLabel {
  //    padding: 0;
  //  }
  //}

  &.tableContentMinHeight {
    min-height: 60px;
    padding: 0;
  }
}

// style scrollbar other screen
.scrollbarTrackX {
  background-color: transparent !important;
  border-radius: 10px !important;
  height: 7px !important;
  bottom: 7px !important;
  left: 15px !important;
  width: calc(100% - 35px) !important;
  z-index: 1 !important;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

.scrollbarTrackY {
  background-color: transparent !important;
  border-radius: 10px !important;
  width: 7px !important;
  height: calc(100% - 55px) !important;
  top: 45px !important;
  right: -9px !important;
  z-index: 2;

  div {
    background-color: #d5dde1 !important;
    border-radius: 10px !important;
  }
}

.scrollableContentReports {
  padding: 0 3px;
  width: 100%;
}

.leftPannel {
  border: 1px solid rgba(226, 235, 244, 0.85);
  width: 32px;
  height: 100%;
}

.simpleTableContainer {
  display: block;
  margin: 0;
  width: 100%;
  padding: 0 8px;
  overflow: hidden;

  .flexTable.header {
    background-color: #edf5fc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top: 1px solid #f5f9fb;
    border-left: 1px solid #f5f9fb;
    border-right: 1px solid #f5f9fb;
    padding: 0 13px;
  }

  .columnName {
    color: #4d7499;
    font-family: "Helvetica Neue Medium", sans-serif;
    font-weight: 500;
  }

  .simpleTableContent {
    padding: 5px 13px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: 1px solid #f5f9fb;
    border-right: 1px solid #f5f9fb;
    border-bottom: 1px solid #f5f9fb;
  }

  .row {
    border-bottom: 1px solid rgba(112, 112, 112, 0.14);
    padding: 0;

    &:last-child {
      border-bottom: none;
    }
  }
}

.comment {
  margin-left: 10px;
  svg {
    width: 12px;
    height: 12px;
  }
}

.simpleTableContainerHidden {
  height: 0;
  border: none;
}

.simpleTableContainerShown {
  height: auto;
}

.flagIcon {
  margin-right: 0.1em;
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.flexCell {
  width: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  //border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
  &:last-child {
    // border-right: 0;
  }
}

.pagination {
  margin-left: auto;
}

.paginationLink {
  color: #808f94;
  font-family: Helvetica Neue, sans-serif;
  text-decoration: none;
}

.paginationLinkActive {
  color: #3e4552;
}

.paginationItem {
  display: inline-flex;
  padding: 0 3px;
}

.overlay {
  background: #fff;
  border-radius: 12px;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  transition: opacity 0.2s;
  display: none;
  justify-content: center;
  align-items: center;
}

.overlayActive {
  opacity: 0.9;
  transition: opacity 0.2s;
  display: flex;
}

.scrollingPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  min-height: 312px;
  box-shadow: 0 3px 16px rgba(226, 233, 237, 0.61);
  background-color: #fff;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  z-index: 1;
}

.scrollingSimplePanel {
  @extend .scrollingPanel;

  z-index: 2;
  box-shadow: none;
}

.iconButton {
  @extend %btn_unset;
  @extend %button-hover;

  &.deselect {
    filter: grayscale(1);
  }
}

.iconButtonRow {
  //width: 15px;
  //height: 15px;
  max-width: 18px;
  max-height: 18px;

  &:last-child {
    margin-left: 5px;
  }
}

// global style table
.scrollableContent {
  // overflow: scroll;
  // height: calc(100vh - 180px) !important;
  min-height: 265px;
  width: calc(100% - 21px) !important;

  &__scrollbarTrackX {
    background-color: transparent !important;
    border-radius: 10px !important;
    height: 7px !important;
    bottom: 7px !important;
    left: 15px !important;
    width: calc(100% - 35px) !important;
    z-index: 1 !important;

    div {
      background-color: #d5dde1 !important;
      border-radius: 10px !important;
    }
  }

  /* stylelint-disable */
  &__scrollbarTrackY {
    background-color: transparent !important;
    border-radius: 10px !important;
    width: 7px !important;
    height: calc(100% - 55px) !important;
    top: 45px !important;
    right: -9px !important;
    z-index: 2;

    div {
      background-color: #d5dde1 !important;
      border-radius: 10px !important;
    }
  }

  &.scrollableContentMinHeight {
    min-height: 60px;
  }
}

.tooltip {
  background-color: #fff !important;
  border-radius: 4px !important;
  border: none !important;
  opacity: 1 !important;
  padding: 7px 14px !important;
  color: #333945 !important;
  font-family: Helvetica Neue, sans-serif !important;
  font-size: 12px !important;
  box-shadow: 0 2px 8px rgba(#647d93, 0.2) !important;

  &::after {
    border-top-color: #fff !important;
  }

  &::before {
    border-left: 8px solid transparent !important;
    border-right: 8px solid transparent !important;
    margin-left: -8px !important;
  }
}

.footerButton {
  margin-top: -5px;
}

.tableContainer {
  background-color: #fff;
  display: block;
  //margin: 2em auto;
  width: 100%;
  min-height: 313px;
  border: 1px solid rgba(226, 235, 244, 0.85);
  border-radius: 12px;
  //padding-top: 10px;
  position: relative;

  &[data-id='first'] .absoluteActions {
    left: calc(var(--hover-actions-left) + var(--scroll-left-first));
  }

  &[data-id='second'] .absoluteActions {
    left: calc(var(--hover-actions-left) + var(--scroll-left-second));
  }

  .row.rowSelected .rowWrapper {
    background-color: #ecf1f9;
  }

  .scrollableContentWithoutRightPanel {
    width: 100% !important;

    & .scrollableContent__scrollbarTrackY {
      right: 10px !important;
    }

    & .absoluteActions {
      background: white;
      width: 70px;
    }

    .rowSelected .absoluteActions {
      background-color: #ecf1f9;
    }

    .flexRowGroup {
      &:last-of-type {
        padding-right: 80px;
      }
    }

    .flexRowGlobal {
      &:last-of-type {
        padding-right: 62px;
      }
    }
  }

  &_greyTitle {
    .flexTable.header .columnName {
      color: #808f94;
    }
  }

  &_grey {
    background-color: #fcfdfd;

    .simpleTable {
      background-color: #fcfdfd;
      border-bottom-color: #e6e6e6;
    }

    .sortBlock {
      color: #808f94;
    }

    .tableContent {
      .row .absoluteActions {
        background: #fcfdfd !important;
      }

      .rowSelected .absoluteActions {
        background-color: #ecf1f9 !important;
      }
    }
  }

  &_white {
    .headerWrapper {
      background-color: white;
      box-shadow: unset;

      .flexTable.header .columnName {
        color: #808f94;
      }
    }
  }

  &_reports {
    .footerTitle {
      margin-right: 0;
      margin-left: 0;
      padding-right: 0;
      width: 14%;

      &_first {
        width: 25%;
      }

      &:last-child {
        text-align: end;
        padding-right: 10px;
      }
    }

    .tableFooter {
      padding-right: 31px;

      &__spacer {
        height: 1px;
        width: 1px;
        margin-right: auto;
      }
    }
  }

  &_accountList {
    .scrollingPanel {
      box-shadow: none;
      border: 1px solid rgba(226, 235, 244, 0.85);
      right: -1px;
      top: -1px;
      bottom: -1px;
      position: absolute;
      height: auto;
    }

    .scrollableContent {
      &__scrollbarTrackX {
        display: flex !important;
        align-items: center;
        width: auto !important;
        height: 32px !important;
        padding: 0 17px !important;
        background: #fff !important;
        left: -1px !important;
        right: -22px !important;
        bottom: 1px !important;
        border-radius: 0 0 12px 12px !important;
        border: 1px solid rgba(226, 235, 244, 0.85);
        z-index: 2 !important;

        div {
          height: 7px !important;
        }
      }

      &__scrollbarTrackY {
        bottom: 45px !important;
      }
    }

    .tableFooter:empty {
      display: none;
    }

    :global .ScrollbarsCustom-Scroller {
      margin-bottom: 5px !important;
    }
  }

  &_simpleTable {
    background-color: $input-background-color;

    .headerWrapper {
      background-color: $input-background-color;
      border-bottom: $input-border-style;
    }

    .scrollingPanel {
      background-color: transparent;
    }
  }

  &_minHeight {
    min-height: 60px;

    > div {
      > div {
        position: static !important;

        > div {
          position: static !important;
        }
      }
    }
  }
}
.margin0{
  margin: 0 90px 0 30px;
}

.addBreak {
  background: #0085ff;
  color: white;
  font-size: 16px;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  line-height: 1;
  cursor: pointer;
}

.existedBreak {
  background: #ECF4FA;
  border-radius: 4px;
  padding: 2px 5px;

  &:hover {
    background: #CEE5F7;
  }
}

.rttOff {
  background: #0085ff;
  border-radius: 4px;
  color: white;
  padding: 2px 4px;
  white-space: nowrap;
  font-size: 12px;
}