.resourceItem {
  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__buttonDots {
    position: absolute;
    right: 2px;
  }

  &__empty {
    position: static !important;
    width: auto !important;
    height: auto !important;
  }

  &__title {
    font-family: "Helvetica Neue Medium", sans-serif;
    font-size: 14px;
    color: black;
    padding-bottom: 12px;
    width: 168px;
  }

  &__name {
    display: flex;
    flex-direction: column;
  }

  &__skill {
    color: #808F94;
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 14px;
    max-width: 270px;
  }

  &__space {
    margin-top: -7px;
  }

  &__demand {
    position: static !important;
    width: auto !important;
    height: auto !important;
    border: 1px solid #d0d6db;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 3px;
    margin-left: auto;
    margin-right: 10px;

    &_red {
      color: #AC6363;
    }

    &_gray {
      color: #7F7F7F;
    }

    &_green {
      color: #05985F;
    }

    &_orange {
      color: #FD9D27;
    }
  }
}
