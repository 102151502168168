@import "../../variables";

$padding-size: 25px;

.formItem {
  margin-bottom: $padding-size;
  position: relative;

  & > button {
    position: absolute;
    top: -8px;
    right: 0;
    z-index: 2;
  }

  & label + div {
    margin-top: unset;
    width: 100%;
  }

  &:last-child {
    margin-bottom: unset;
  }
}

small.error {
  color: $red-600;
}

.horSep {
  border: none;
  border-top: 1px solid #d0dfef82;
  margin-bottom: 20px;
}

.labelSpan {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  label + div {
    width: 18px;
    margin: 0;
  }
}

.selectdisabled__tooltip {
  background-color: #F7F7B4 !important;
  color: black !important;

  &::after,
  &::before {
    border-top-color: #F7F7B4 !important;
  }
}
