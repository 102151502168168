.addEntry {
    padding: 0 24px;
    margin-bottom: 20px;
  
    & > * {
      margin-top: 10px;
    }

    &__form {
      min-width: 450px;
    }
  
    &__line {
      width: 100%;
      height: 1px;
      margin: 10px 0;
      background: #d0dfef;
    }

    &__sep {
        width: 100%;
        height: 1px;
        margin: 5px 0;
      }

    &__formFlex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        > div {
            flex: 1;
        }
    }

    &__formButtonsFlex {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }

    &__formSmallButtonsFlex {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
    }
  
    &__formControl {
      margin-top: 10px;
      margin-bottom: 10px;
  
      &__labelBlock {
        display: flex;
  
        label {
          margin-right: 20px;

          > span:last-child {
            color: #808F94;
            font-size: 14px;
            font-weight: 500;
          }
        }
      }

      &__smallSelect {
        max-width: 150px;

        > div {
            height: 30px;
            min-height: 30px;
        }

        input {
            padding: 5px 11px;
        }
      }

      &__smallInput {
        max-width: 150px;
        height: 14px;
        min-height: 14px;
        padding: 5px 11px;
        font-family: Helvetica Neue, sans-serif;
        border: 1px solid #d9dfe3;
        border-radius: 4px;
        color: #333945;
      }
    }

    &__formCheckbox {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: -9px;
    }

    &__date {
        label {
            display: none;
        }

        input {
            background-color: #fcfdfd;
            border: 1px solid rgba(0, 133, 255, 0.1);
            border-radius: 4px;
            box-sizing: border-box;
            color: #333945;
            font-family: Helvetica Neue, sans-serif;
            font-size: 15px;
            padding: 0 11px;
            outline: none;
            margin: 0;
            height: 36px;
        }

        div {
            margin: 0 !important;
            padding: 0;

            &::after,
            &::before {
                display: none;
            }
        }
    }
  
    &__infoBlock {
      display: flex;
      align-items: center;
      padding: 0 11px;
      height: 42px;
      background-color: #f5f9fc;
      border-radius: 13px;
  
      & > span {
        &:nth-child(2) {
          margin-right: auto;
        }
  
        &:nth-child(4) {
          margin-right: 19px;
        }
      }
  
      &__label {
        margin-right: 5px;
        color: #cbd1d8;
        font-size: 13px;
        line-height: 16px;
        font-family: "Helvetica Neue Bold", sans-serif;
      }
  
      &__value {
        font-size: 18px;
        line-height: 39px;
        font-family: "Helvetica Neue Bold", sans-serif;
        color: #333945;
      }
    }
}

.buttonSaveBlock {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    button {
      width: 100%;
    }
}
  
.buttonsBlock {
    @extend .buttonSaveBlock;

    button {
        margin: 0 10px;
    }
}

.textarea {
    background-color: #fcfdfd;
    border: 1px solid rgba(0, 133, 255, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
    color: #333945;
    font-family: Helvetica Neue, sans-serif;
    font-size: 15px;
    padding: 0 11px;
    outline: none;
    margin: 0;

    textarea {
        width: 100%;
    }
}